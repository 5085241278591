import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { FirstFooter, SecondFooter } from "./styleComponent";
import Logo from "../../assets/logo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

function Index({ dataCategoryProduct, dataAboutUs }) {
  const history = useHistory();
  return (
    <div
      style={{
        margin: "8px auto",
      }}
    >
      {/* MEDSOS */}
      {/* Social Media Section */}
      <Grid
        style={{
          backgroundColor: "#ff0000",
          display: "flex",
          justifyContent: "center",
          padding: "10px 0",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: 1100,
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
        >
          <b style={{ marginRight: 20 }}>Follow Us:</b>
          <FacebookIcon style={{ marginRight: 10, cursor: "pointer" }} /> Virus
          Keeper.ID
          <InstagramIcon style={{ marginLeft: 20, cursor: "pointer" }} />{" "}
          @lucasoil.id @polaheal
        </Grid>
      </Grid>

      {/* Main Footer Section */}

      <Grid
        style={{
          backgroundColor: "#aeaeae",
          display: "flex",
          justifyContent: "center",
          padding: "20px 0",
        }}
      >
        <Grid
          container
          style={{ maxWidth: 1100, display: "flex", padding: 10 }}
        >
          <Grid item xs={12} md={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                backgroundColor: "#aeaeae",
              }}
            >
              <img src={Logo} alt="Pola Lubindo" style={{ height: 30 }} />
              <h3 style={{ margin: 0, fontWeight: "bold" }}>PT Pola Lubindo</h3>
            </div>
          </Grid>
          {/* Company Info */}
          <Grid item xs={12} md={5} style={{ paddingRight: 20 }}>
            <div>
              <b style={{ fontSize: 15 }}>Kantor Pusat</b>
              <p style={{ fontSize: 14, margin: 0 }}>Jl. Penjernihan No 40</p>
              <p style={{ fontSize: 14, margin: 0 }}>
                Jakarta Pusat, Jakarta - Indonesia
              </p>
              <p style={{ fontSize: 14, margin: 0 }}>
                Phone : +62(21) 571 2644
              </p>
              <p style={{ fontSize: 14, margin: 0 }}>Fax : +62(21) 571 2644</p>
              <p style={{ fontSize: 14, margin: 0 }}>
                Mail : sales@polalubindo.co.id
              </p>
              <p style={{ fontSize: 14, margin: 0 }}>
                Website : www.polalubindo.co.id
              </p>
            </div>
            <div style={{ marginTop: 10 }}>
              <b style={{ fontSize: 15 }}>Tentang Kami</b>
              <p style={{ fontSize: 14, margin: 0 }}>Tentang Kami</p>
              <p style={{ fontSize: 14, margin: 0 }}>Hubungi Kami</p>
              <p style={{ fontSize: 14, margin: 0 }}>Rekomendasi</p>
              <p style={{ fontSize: 14, margin: 0 }}>Reseller</p>
            </div>
          </Grid>

          {/* Products & About Us Sections */}
          <Grid item xs={12} md={3} style={{ paddingRight: 20, marginTop: 10 }}>
            <b style={{ fontSize: 15 }}>Produk</b>
            <p style={{ fontSize: 14, margin: 0 }}>Pola Heal</p>
            <p style={{ fontSize: 14, margin: 0 }}>Virus Keeper</p>
            <p style={{ fontSize: 14, margin: 0 }}>Lucas Oil</p>
            <p style={{ fontSize: 14, margin: 0 }}>Tacbecon</p>
            <p style={{ fontSize: 14, margin: 0 }}>Anderol</p>
            <p style={{ fontSize: 14, margin: 0 }}>Prelube</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.471497120992!2d106.8047779!3d-6.2013604!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f6a33f5ec3c7%3A0xd53db2163306af2b!2sPT.%20Pola%20Lubindo!5e0!3m2!1sid!2sid!4v1741931933901!5m2!1sid!2sid"
              width="400"
              height="300"
              style={{ border: 0 }}
              title="PT Pola Lubindo Location"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid>

          {/* Google Maps */}
        </Grid>
      </Grid>
      <Grid
        style={{
          backgroundColor: "#303030",
          padding: "30px auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
        }}
      >
        <p style={{ color: "#ffffff", fontSize: 10 }}>
          © Copyright 2022 PT Pola Lubindo, All Right Reserved
        </p>
      </Grid>
    </div>
  );
}

const mapStateToProps = ({ dataCategoryProduct, dataAboutUs }) => {
  return {
    dataCategoryProduct,
    dataAboutUs,
  };
};

export default connect(mapStateToProps)(Index);
